
import React from 'react'
import Sales from '../../../templates/sales'

const SalesEsPage = () => {
    return (
        <Sales lang="es">
        </Sales>
    )
}

export default SalesEsPage
