import React, { useState }  from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Modal from 'react-modal'

import Layout from '../components/layout'
import Nav from '../components/nav'
import SEO from "../components/seo"
import FaqCard from '../components/content/faqCard'
import '../styles/index.scss'

import OptBtn from '../components/button'
import ContactForm from '../components/contactForm'

// Content components
import Integrations from '../components/content/integrations'
import Stories from '../components/stories'

// images
import agent_view from '../images/products/multiagentes/agent_gif.gif';

// channels
import bannerImg from '../images/home/redesign01/banner-home.png';
import u01Img from '../images/home/redesign01/u01.png';
import u02Img from '../images/home/redesign01/u02.png';
import u03Img from '../images/home/redesign01/u03.png';
import u04Img from '../images/home/redesign01/u04.png';
import u05Img from '../images/home/redesign01/u05.png';
import u06Img from '../images/home/redesign01/u06.png';
import mobile from '../images/home/redesign01/mobile.png';

// customers
import customer_aldeas from '../images/home/clients/customer_aldeas.png';
import customer_baristi from '../images/home/clients/customer_baristi.png';
import customer_ccti from '../images/home/clients/customer_ccti.png';
import customer_conoc from '../images/home/clients/customer_conoc.png';
import customer_crucijuegos from '../images/home/clients/customer_crucijuegos.png';
import customer_descuentos from '../images/home/clients/customer_descuentos.png';
import customer_ginza from '../images/home/clients/customer_ginza.png';
import customer_ioimporto from '../images/home/clients/customer_ioimporto.png';
import customer_mcc from '../images/home/clients/customer_mcc.png';
import customer_med from '../images/home/clients/customer_med.png';
import customer_reba from '../images/home/clients/customer_reba.png';
import customer_san_lorenzo from '../images/home/clients/customer_san_lorenzo.png';
import customer_whyline from '../images/home/clients/customer_whyline.png';
import customer_xe_seguridad from '../images/home/clients/customer_xe_seguridad.png';


function Sales({lang}){

    let text = {
        es: {
            cs_jobTitle_1: "CEO en Reba",
            cs_title_1: "La mejor plataforma para atención automatizada y centralizada en WhatsApp",
            cs_description_1: "Encontramos en Optiwe el partner ideal para mejorar significativamente la experiencia de servicio a nuestros clientes. En solo unos pocos meses pudimos automatizar el 40% de los mensajes/preguntas de nuestros clientes y ofrecer atención personalizada con AI 24x7.",
            cs_jobTitle_2: "Asesora de Campaña",
            cs_title_2: "El mejor CRM para WhatsApp",
            cs_description_2: "En Optiwe encontramos el partner ideal para escalar nuestro servicio al donante a través de nuestro sitio web y WhatsApp. También, gracias a sus herramientas de outbound, podemos comunicarnos con nuevos potenciales donantes en tiempo real y brindarles la mejor experiencia posible. Estamos muy felices de contar con la tecnología de Optiwe para potenciar a nuestros equipos de atención al donante.",
            page_title: "Optiwe - WhatsApp Multiagente y CRM para Atención de Clientes",
            page_description: "Plataforma multiagente y multicanal para ateción de clientes y ventas en WhatsApp, Instagram, Facebook Messenger y LiveChat.",
            page_lang: "es",
            h1_normal: "WhatsApp Multiagente para equipos de ventas",
            h1_description_1: "En la actualidad, los clientes buscan comprar productos y servicios por los mismos canales que utilizan para comunicarse diariamente: WhatsApp, Instagram, Facebook, etc. Con Optiwe podrás conectar a todo tu equipo a un único número, centralizar las conversaciones de todos los canales y monitorear tus ventas desde una única plataforma.",
            demo: "Agendar demo",
            test: "Probar gratis",
            customers: "Sumate a las empresas que ya usan Optiwe para hacer crecer sus negocios y mejorar la comunicación con sus clientes",
            h2_benefits: "Impulsa tus ventas con Inteligencia Artificial",
            h2_benefits_description: "Optiwe utiliza inteligencia artificial para recomendar respuestas a tus vendedores para que puedan responder más rápido y cerrar más ventas",
            h2_benefits_alt: "Vista de agente de atención de Optiwe",
            h2_productos: "Herramientas y beneficios",
            h2_prod_1_title: "WhatsApp Business API Multiagente",
            h2_prod_1_description: "Atiende a tus clientes con un único número de WhatsApp Business compartido entre todos los dispositivos móviles de tus agentes y sin necesidad de que utilicen su WhatsApp personal.",
            h2_prod_1_alt: "WhatsApp Business API para conectar todo tu equipo a un único número telefónico",
            h2_prod_2_title: "Atención Omnicanal",
            h2_prod_2_description: "Solución MultiAgente para gestionar la atención en todos tus canales: Whatsapp, Facebook, Instagram, Web LiveChat, desde una única plataforma.",
            h2_prod_2_alt: "Comunicación omnicanal entre empresas y clientes",
            h2_prod_3_title: "Gestión centralizada",
            h2_prod_3_description: "Ten a todo tu equipo en línea, segmentado por grupos y trabajando en colaboración.",
            h2_prod_3_alt: "Bandeja de entrada multicanal de Optiwe",
            h2_prod_4_title: "Chatbots de atención 24x7",
            h2_prod_4_description: "Permite a tus clientes obtener información y responder preguntas sobre los productos de tu empresa 24x7. Crea flujos automatizados para tus procesos de ventas.",
            h2_prod_4_alt: "Inteligencia Artificial para recomendación de respuestas",
            h2_prod_5_title: "Captación de clientes potenciales",
            h2_prod_5_description: "El chatbot también permite recolectar los datos del cliente y los productos que desea comprar, para derivarlo a un agente de atención con toda la información necesaria para cerrar la venta más rápidamente",
            h2_prod_5_alt: "Historial centralizado de conversacions",
            h2_prod_6_title: "Software de atención multidispositivo",
            h2_prod_6_description: "Funcionamiento tanto en la computadora de escritorio como en dispositivos móviles, con notificaciones y alertas para tus vendedores. Recibirán notificaciones en tiempo real cuando una nueva conversación esté disponible en su bandeja de entrada.",
            h2_prod_6_alt: "Soporte para computadoras de escritorio y dispositivos móviles",
            h2_prod_7_title: "Asignación automática de vendedores ",
            h2_prod_7_description: "Con el algoritmo de auto-asignación podras derivar la atención de manera rotativa entre los vendedores disponibles y respetar el esquema de comisiones.",
            h2_prod_7_alt: "Etiquetas de conversaciones",
            h2_prod_8_title: "Integración con CRM",
            h2_prod_8_description: "Vincula nuestra plataforma con tus sistemas de gestion para automatizar procesos como carga de datos,confirmación de pedidos,etc.",
            h2_prod_8_alt: "Interfaz de derivación de conversaciones entre agentes de atención",
            h2_prod_9_title: "Reducción de costos",
            h2_prod_9_description: "Al tener todo centralizado puedes olvidarte de los costos en dispositivos móviles y planes telefónicos para tu equipo de ventas, así como optimizar los tiempos administrativos y de supervisión",
            h2_prod_9_alt: "Intercambio de archivos multimedia y mensajes de audio",
            h2_prod_10_title: "WhatsApp Marketing",
            h2_prod_10_description: "Inicia conversaciones con potenciales clientes de manera masiva y asigna las oportunidades a tu equipo de ventas para aumentar la facturación de tu negocio.",
            h2_prod_10_alt: "Creación de nuevas conversaciones y envíos masivos",
            h2_prod_11_title: "Inteligencia artificial",
            h2_prod_11_description: " Para identificar las intenciones e intereses de tus clientes y recomendar a los vendedores las mejores respuestas para cerrar las ventas más rápido",
            h2_prod_11_alt: "Historial centralizado de conversaciones",
            h2_prod_12_title: "Monitoreo en tiempo real",
            h2_prod_12_description: "Monitoreo en tiempo real e historico para saber cómo están trabajando tus agentes y los clientes pendientes de atender. Obtención de estadisticas para evaluación de desempeño.",
            h2_prod_12_alt: "Reportes sobre como trabajan los agentes de atención",
            register: "Regístrate, es gratis!",
            h2_cs: "Las empresas que más venden y mejor atienden a sus clientes ya utilizan Optiwe",
            h2_canales: "Ventas y soporte omnicanal con WhatsApp Multiagente",
            h2_canales_h3: "Comunícate en los canales donde tus clientes te están buscando.",
            h2_canales_description: "Utiliza nuestra plataforma de atención en WhatsApp, Instagram, Facebook, tu sitio web y muchos canales más. Te ayudamos a crear chatbots y reglas de derivación inteligentes a agentes de atención, para ofrecer la mejor experiencia omnicanal a todos tus clientes!",
            h2_canales_whatsapp_platform: "Plataforma WhatsApp Business",
            h2_canales_whatsapp_business_api: "WhatsApp Business API Oficial",
            h2_integrations: "¿Qué integraciones ofrece Optiwe?What integrations does Optiwe offer?",        
            talk: "¡Hablemos!",
            h2_faq: "Preguntas Frecuentes",
            faq_1: "¿Cuáles son los beneficios que ofrece Optiwe?",
            faq_1_description: "Optiwe es la solución que te permite vender productos y chatear con tus clientes por WhatsApp, Instagram, Facebook y Sitio Web. Con unos pocos clicks puedes integrar todos tus canales digitales en nuestra plataforma, automatizar ventas y preguntas frecuentes de tus clientes, derivar conversaciones a tu equipo de ventas y realizar campañas de marketing por WhatsApp. Nuestra solución es simple de utilizar y al mismo tiempo incluye todas las herramientas de ventas que tu negocio necesita. Encuentra más información en nuestro blog sobre cómo nuestra herramienta se compara con otras empresas como Callbell, B2Chat y Cliengo.",
            faq_2: "¿Puedo usar Optiwe para vender por WhatsApp?",
            faq_2_description: "Optiwe te permite gestionar las conversaciones con tus clientes por WhatsApp y vender tus productos o servicios de manera centralizada y ordenada. Con la solución de Optiwe puedes mantener a todo tu equipo de ventas colaborando en un único lugar, monitorear en tiempo real las conversaciones que tus vendedores tienen con tus clientes y mejorar la experience de venta y atención. Tus vendedores pueden usar Optiwe desde sus celulares o computadoras y puedes asignar clientes de manera automática para repartir oportunidades y comisiones entre tus vendedores.",
            faq_3: "¿Puedo usar Optiwe para atender clientes por WhatsApp?",
            faq_3_description: "Optiwe cuenta con una bandeja de entrada de conversaciones (similar a una cuenta de email) donde todo tu equipo de atención al cliente puede trabajar de manera colaborativa para resolver todas las consultas de tus clientes. En esta bandeja de conversaciones tu equipo de atención recibirá conversaciones de WhatsApp, Facebook, Instagram, Web Chat y más canales, de manera completamente centralizada y fácil de utilizar. Con la funcionalidad de respuestas rápidas y sugeridas de Optiwe, tu equipo de atención va a trabajar más rápidamente y logrará ofrecer una mejor experiencia a todos tus clientes. Optiwe es la plataforma #1 del mercado para WhatsApp Multiagente y WhatsApp Multidispositivo",
            faq_4: "¿Puedo usar Optiwe en varios dispositivos?",
            faq_4_description: "Con Optiwe, puedes conectar a todos tus vendedores y agentes de atención a una única linea de WhatsApp Business. Cada miembro de tu equipo puede utilizar su email y contraseña para ingresar a Optiwe desde su dispositivo móvil y comenzar a responder de manera centralizada los mensajes que tus clientes envían a la línea de WhatsApp Business de tu negocio. Nunca más tendrás que pagar por líneas móviles y teléfonos para tu equipo de atención al cliente, ya que la solución multiplataforma de Optiwe puede ser instalada en sus teléfonos personales sin interferir con el WhatsApp personal de los integrantes de tu equipo. Optiwe te permite facilmente incorporar WhatsApp Business en Varios Dispositivos a tu negocio sin lïmites en la cantidad de agentes de atención",
            faq_5: "¿Puedo usar Optiwe cómo un CRM para WhatsApp e Instagram?",
            faq_5_description: "Optiwe te permite centralizar y monitorear todas las conversaciones que tu equipo de ventas y atención tienen con tus clientes. La información de tus clientes como nombre, teléfono, email y conversaciones queda almacenado de manera segura en nuestra plataforma. Puedes crear etiquetas de conversaciones para darle seguimiento a tus clientes y construir tu embudo de ventas. Cada conversación queda asignada con un número de identificación único que te permite darle seguimiento de manera correcta a los reclamos de tus clients.",
        },
        en: {
            cs_jobTitle_1: "CEO at Reba",
            cs_title_1: "The best platform for automated and centralized attention on WhatsApp",
            cs_description_1: "We found in Optiwe the ideal partner to significantly improve the customer service experience. In just a few months, we were able to automate 40% of our customers' messages/questions and offer personalized AI assistance 24x7.",
            cs_jobTitle_2: "Campaign Advisor",
            cs_title_2: "The best application for Whatsapp Business Multiple Users",
            cs_description_2: "At Optiwe, we found the ideal partner to scale our donor service through our website and WhatsApp. Thanks to their outbound tools, we can communicate with new potential donors in real-time and provide them with the best possible experience. We are very happy to have Optiwe's technology to empower our donor support teams.",
            page_title: "Optiwe - Whatsapp Business Multiple Users and CRM for Customer Service and Sales",
            page_description: "Multi-agent and multi-channel platform for customer service and sales on WhatsApp, Instagram, Facebook Messenger, and LiveChat.",
            page_lang: "en",
           h1_normal: "WhatsApp Multi-Agent for Sales Teams",
           h1_description_1: "Currently, customers are looking to purchase products and services through the same channels they use to communicate on a daily basis: WhatsApp, Instagram, Facebook, etc. With Optiwe, you can connect your entire team to a single number, centralize conversations from all channels, and monitor your sales from a single platform.",
            demo: "Schedule demo",
            test: "Free trial",
            customers: "Join the companies that already use our WhatsApp Business CRM to grow their businesses and improve communication with their customers",
            h2_benefits: "Boost your sales with Artificial Intelligence",
            h2_benefits_description: "Optiwe uses artificial intelligence to recommend responses to your salespeople so they can respond faster and close more sales.",
            h2_benefits_alt: "Optiwe's agent view",
            h2_productos: "Tools and Benefits",
            h2_prod_1_title: "WhatsApp Business API Multi-Agent",
            h2_prod_1_description: "Serve your customers with a single shared WhatsApp Business number among all your agents' mobile devices without them needing to use their personal WhatsApp.",
            h2_prod_1_alt: "WhatsApp Business API to connect your entire team to a single phone number",
           h2_prod_2_title: "Omnichannel Support",
           h2_prod_2_description: "Multi-Agent solution to manage support on all your channels: WhatsApp, Facebook, Instagram, Web LiveChat, from a single platform.",
            h2_prod_2_alt: "Omnichannel communication between companies and customers",
            h2_prod_3_title: "Centralized Management",
            h2_prod_3_description: "Have your entire team online, segmented by groups and working collaboratively.",
            h2_prod_3_alt: "Optiwe's multichannel inbox",
            h2_prod_4_title: "24x7 Support Chatbots",
            h2_prod_4_description: "Allow your customers to obtain information and answer questions about your company's products 24x7. Create automated flows for your sales processes.",
            h2_prod_4_alt: "Artificial Intelligence for response recommendations",
            h2_prod_5_title: "Lead Generation",
            h2_prod_5_description: "The chatbot also allows you to collect customer data and the products they wish to purchase, and then refer them to a support agent with all the necessary information to close the sale more quickly.",
            h2_prod_5_alt: "Centralized conversation history",
            h2_prod_6_title: "Multidevice Support Software",
            h2_prod_6_description: "Works on both desktop and mobile devices, with notifications and alerts for your salespeople. They will receive real-time notifications when a new conversation is available in their inbox.",
            h2_prod_6_alt: "Support for desktop computers and mobile devices",
            h2_prod_7_title: "Automatic Assignment of Salespeople",
            h2_prod_7_description: "With the auto-assignment algorithm, you can rotate support among available salespeople and respect the commission scheme.",
            h2_prod_7_alt: "Conversation tags",
            h2_prod_8_title: "CRM Integration",
            h2_prod_8_description: "Link our platform with your management systems to automate processes such as data loading, order confirmation, etc.",
            h2_prod_8_alt: "Interface for conversation referral between support agents",
            h2_prod_9_title: "Cost Reduction",
            h2_prod_9_description: "By having everything centralized, you can forget about the costs of mobile devices and phone plans for your sales team, as well as optimize administrative and supervisory times.",
            h2_prod_9_alt: "Exchange of multimedia files and audio messages",
            h2_prod_10_title: "WhatsApp Marketing",
            h2_prod_10_description: "Start conversations with potential customers en masse and assign opportunities to your sales team to increase your business's revenue.",
            h2_prod_10_alt: "Creation of new conversations and mass messaging",
            h2_prod_11_title: "Artificial Intelligence",
            h2_prod_11_description: "To identify the intentions and interests of your customers and recommend the best responses to your sales team for faster closing of deals.",
            h2_prod_11_alt: "Centralized conversation history",
            h2_prod_12_title: "Real-time monitoring",
            h2_prod_12_description: "Real-time and historical monitoring to know how your agents are working and which customers are still pending. Obtaining statistics for performance evaluation.",
            h2_prod_12_alt: "Reports on how customer service agents are working.",
            register: "Sign up, it's free!",
            h2_cs: "The companies that sell the most and provide the best customer service are already using Optiwe",
            h2_canales: "Omnichannel sales and support",
            h2_canales_h3: "Communicate on the channels where your customers are looking for you.",
            h2_canales_description: "Use our sales platform on >WhatsApp, Instagram, Facebook, your website, and many other channels. We help you create smart chatbots and routing rules to sales agents, to offer the best omnichannel experience to all your customers!",
            h2_canales_whatsapp_platform: "WhatsApp Business Platform",
            h2_canales_whatsapp_business_api: "Official WhatsApp Business API",
            h2_integrations: "What integrations does Optiwe offer?",
            talk: "Let's talk!",
            h2_faq: "Frequently Asked Questions",
            faq_1: "What benefits does Optiwe offer?",
            faq_1_description: "Optiwe is the solution that allows you to sell products and chat with your customers via WhatsApp, Instagram, Facebook, and your website. With a few clicks, you can integrate all your digital channels in our platform, automate sales and frequently asked questions from your customers, route conversations to your sales team, and conduct marketing campaigns via WhatsApp. Our solution is easy to use and at the same time includes all the sales tools your business needs. Find more information on our blog about how our tool compares to other companies like Callbell, B2Chat, and Cliengo.",
            faq_2: "Can I use Optiwe to sell on WhatsApp?",
            faq_2_description: "Optiwe allows you to manage conversations with your customers through WhatsApp and sell your products or services in a centralized and organized way. With Optiwe's solution, you can keep your entire sales team collaborating in one place, monitor in real time the conversations that your salespeople have with your customers, and improve the sales and service experience. Your salespeople can use Optiwe from their phones or computers, and you can automatically assign customers to distribute opportunities and commissions among your salespeople.",
            faq_3: "Can I use Optiwe to assist customers through WhatsApp?",
            faq_3_description: "Optiwe has a conversation Whatsapp Shared Inbox (similar to an email account) where your entire customer service team can work collaboratively to resolve all your customers' inquiries. In this Whatsapp Shared Inbox, your customer service team will receive conversations from WhatsApp, Facebook, Instagram, Web Chat, and more channels, in a completely centralized and easy-to-use way. With Optiwe's quick and suggested response functionality, your customer service team will work more quickly and achieve a better experience for all your customers.",
            faq_4: "Can I use Optiwe for Whatsapp Multiple Devices?",
            faq_4_description: "With Optiwe, you can connect all your salespeople and customer service agents to a single WhatsApp Business line. Each member of your team can use their email and password to log into Optiwe from their mobile device and start centrally responding to messages that your customers send to your business's WhatsApp Business line. You will never again have to pay for mobile lines and phones for your customer service team, as Optiwe's multi-platform solution can be installed on their personal phones without interfering with the personal WhatsApp of your team members.",
            faq_5: "Can I use Optiwe as a CRM for WhatsApp and Instagram?",
            faq_5_description: "Optiwe allows you to centralize and monitor all the conversations that your sales and customer service team have with your customers. Your customers' information, such as name, phone number, email, and conversations, is securely stored on our platform. You can create conversation tags to follow up with your customers and build your sales funnel. Each conversation is assigned a unique identification number that allows you to correctly track your customers' claims."            
        }
    }
    
    /*
    ***************
    * FORM MODAL *
    ***************
    */
   const [modalIsOpen, setModalIsOpen] = useState(false)

   function afterOpenModal(){}

   function closeModal(){
       setModalIsOpen(false);
   }

   /*
   ***************
   * SLIDER SETTINGS *
   ***************
   */
   const sliderSettings = {
       dots: true,
       infinite: true,
       autoplay: true,
       speed: 1000,
       autoplaySpeed: 1000,  
       slidesToShow: 5,
       slidesToScroll: 1,
       responsive: [
           {
             breakpoint: 1024,
             settings: {
               slidesToShow: 3,
               slidesToScroll: 3,
               infinite: true,
               dots: true
             }
           },
           {
             breakpoint: 600,
             settings: {
               autoplay: true,
               speed: 2000,
               autoplaySpeed: 2000,  
               slidesToShow: 2,
               slidesToScroll: 2,
               dots: true
             }
           },
           {
             breakpoint: 480,
             settings: {
               autoplay: true,
               speed: 2000,
               autoplaySpeed: 2000,  
               slidesToShow: 2,
               slidesToScroll: 2,
               dots: true
             }
           }
       ]
   };

    /*
    *********************
    * CUSTOMERS STORIES *
    *********************
    */
   const clientStories = [
        {
            id: "1",
            name: "Stefano Angeli",
            jobTitle: text[lang]["cs_jobTitle_1"],
            title: text[lang]["cs_title_1"],
            description: text[lang]["cs_description_1"],
            img: customer_reba,
            stars: 5
        },
        {
            id: "2",
            name: "Noelia Nuñez",
            jobTitle: text[lang]["cs_jobTitle_2"],
            title: text[lang]["cs_title_2"],
            description: text[lang]["cs_description_2"],
            img: customer_aldeas,
            stars: 5
        }
    ]

    return (
        <Layout lang={lang}>
            <SEO
                title={text[lang]["page_title"]}
                description={text[lang]["page_description"]}
                lang={text[lang]["page_lang"]}
                locales={
                    [
                        {"hreflang": "en", "href": "https://optiwe.com/products/shared-whatsapp-inbox/"},
                        {"hreflang": "es", "href": "https://optiwe.com/es/productos/whatsapp-multiagente/"},
                        {"hreflang": "x-default", "href": "https://optiwe.com/products/shared-whatsapp-inbox/"}
                    ]
                }   
            />

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className="main-modal"
            >
                <div className="ow-contact-form">
                <span 
                    tabIndex={0}
                    role="button"
                    className="close-modal" 
                    onKeyDown={() => setModalIsOpen(false)} 
                    onClick={() => setModalIsOpen(false)}
                >
                        ✕
                </span>
                    <ContactForm id="modal" lang={lang} closeCb={() => setModalIsOpen(false)} modal/>
                </div>
            </Modal>

            <main className="container-fluid ow-banner">
                {
                    /*
                    ^*********
                    * HEADER *
                    **********
                    */
                }
                <Nav lang={lang} onDemoCb={modalState => setModalIsOpen(modalState)} showButtons />

                {
                    /*
                    ***************
                    * MAIN BANNER *
                    ***************
                    * TODO: If the banner is used in another view, create a <Banner /> component
                    */
                }
                <div className="container ow-banner__content">
                    <div className="row">
                        <div className="col-xl-6 col-xxl-6 ow-banner__home-content">
                            <h1 className="ow-banner__title">
                                {text[lang]["h1_normal"]}
                            </h1>
                            <p className="ow-banner__text">
                                {text[lang]["h1_description_1"]}
                            </p>
                            <div className="row">
                                <div className="col-xl-4 mb-3 mb-md-3">
                                    <OptBtn clickCb={() => setModalIsOpen(true)} rounded outlined small>{text[lang]["demo"]}</OptBtn>
                                </div>
                                <div className="col-xl-4">
                                    <OptBtn clickCb={() => setModalIsOpen(true)} rounded small>{text[lang]["test"]}</OptBtn>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xxl-7 ow-banner__home-image">
                            <img src={bannerImg} alt="optiwe" className="ow-banner__img" />
                        </div>
                    </div>
                </div>
            </main>

            <section className="container-fluid ow-section ow-section--features" id="features">
                <div className="container success-stories">
                    <div className="row justify-content-center mb-5">
                        <div className="col-md-10">
                            <h2 className="ow-section__title"><span className="bold">{text[lang]["customers"]}</span></h2>
                        </div>
                        <div className="row">
                            <div className="col-md-10">
                            </div>
                        </div>
                    </div>
                    <div className="mb-5">
                        <Slider {...sliderSettings}>
                            <div className="ow-client-wrapper">
                                <img src={customer_aldeas} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_baristi} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_reba} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_ccti} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_conoc} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_crucijuegos} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_descuentos} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_ginza} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_ioimporto} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_mcc} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_med} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_san_lorenzo} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_whyline} alt="optiwe" className="ow-features__img" />
                            </div>
                            <div className="ow-client-wrapper">
                                <img src={customer_xe_seguridad} alt="optiwe" className="ow-features__img" />
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>

            {
                /*
                ****************
                * Key benefit  *
                ****************
                */
            }
            <section className="benefits-shapes">
                <svg id="benefits_top_shape" data-name="benefits_top_shape" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" viewBox="0 0 1920 143.11"><path className="benefits_top_shape" d="M1920,143.11V38.75C1692.68,104.88,1412.86,127.9,1184.41,89,1083.28,71.8,983,44.3,880.43,45.1,705.88,46.4,536.94,129.6,363.09,114c-77.32-7-151.94-33.2-229.26-39.5C89,70.85,41.09,75.79,0,92.94l.22,50.16Z"/><path className="benefits_top_shape" d="M384,72.49c5.25-.88,10.65-1.89,15.23-4.62s8.23-7.75,7.92-13-4.74-9.86-9.87-11.6-10.8-1-15.93.58c-4,1.19-8.2,3-12.06,1.58-4.14-1.57-6.17-6-8.85-9.53C353,25.85,337.1,22.4,327.17,30c-6,4.54-9.81,15.35-7.06,22.55a25.66,25.66,0,0,0,5.42,8c6.64,6.93,17.09,14.23,27,15.22S374,74.25,384,72.49Z"/><path className="benefits_top_shape" d="M443.55,31.61c3.18-3.33,5.56-8.65,2.74-12.32-2.34-3-7.15-3-10.31-.93-1.72,1.09-4.24,3.37-4.92,5.41-1.2,3.23-1.83,9.65,1.81,11.43C436.71,37.19,440.95,34.38,443.55,31.61Z"/></svg>
                <div className="container-fluid ow-section--cards" id="benefits">
                    <div className="container">
                        <h2 className="ow-section--cards__title"><span className="bold">{text[lang]["h2_benefits"]}</span></h2>
                        <p className="ow-section--cards__desc">{text[lang]["h2_benefits_description"]}</p>
                        <img src={agent_view} alt={text[lang]["h2_benefits_alt"]} className="img-responsive" />
                    </div>
                </div>
                <svg id="benefits_bottom_shape" className="benefits_bottom_shape" preserveAspectRatio="xMidYMid slice" viewBox="0 0 1920 143.11" data-name="benefits_bottom_shape" xmlns="http://www.w3.org/2000/svg"><path className="benefits_bottom_shape" d="M0,0V25.07C100.42,73.33,222.92,85.71,339.3,81.39c156.8-5.8,310.1-35.9,467.5-35.3,150.2.5,300.5,20.5,450.6,13.9,134.5-5.9,267.1-42.9,402.7-27.6,84,9.52,172.75,28.69,259.9,33.2V0Z"/></svg>
            </section>

            {
                /*
                ************
                * FEATURES *
                ************
                */
            }
            <section className="container-fluid ow-section ow-section--features" id="features">
                <div className="container features">
                    <div className="row">
                        <div className="col-md-12 features__content">
                            <h2 className="ow-section__title"><span className="bold">{text[lang]["h2_productos"]}</span></h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="ow-features__title">{text[lang]["h2_prod_1_title"]}</h3>
                            <p className="ow-features__text">{text[lang]["h2_prod_1_description"]}</p>
                            <img className="ow-features__img" src={u01Img} alt={text[lang]["h2_prod_1_alt"]}/>
                        </div>
                        <div className="col-md-6">
                            <h3 className="ow-features__title">{text[lang]["h2_prod_2_title"]}</h3>
                            <p className="ow-features__text">{text[lang]["h2_prod_2_description"]}</p>
                            <img className="ow-features__img" src={u04Img} alt={text[lang]["h2_prod_2_alt"]}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="ow-features__title">{text[lang]["h2_prod_3_title"]}</h3>
                            <p className="ow-features__text">{text[lang]["h2_prod_3_description"]}</p>
                            <img className="ow-features__img" src={u02Img} alt={text[lang]["h2_prod_3_alt"]}/>
                        </div>
                        <div className="col-md-6">
                            <h3 className="ow-features__title">{text[lang]["h2_prod_4_title"]}</h3>
                            <p className="ow-features__text">{text[lang]["h2_prod_4_description"]}</p>
                            <img className="ow-features__img" src={u05Img} alt={text[lang]["h2_prod_4_alt"]}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="ow-features__title">{text[lang]["h2_prod_5_title"]}</h3>
                            <p className="ow-features__text">{text[lang]["h2_prod_5_description"]}</p>
                            <img className="ow-features__img" src={u03Img} alt={text[lang]["h2_prod_5_alt"]}/>
                        </div>
                        <div className="col-md-6">
                            <h3 className="ow-features__title">{text[lang]["h2_prod_6_title"]}</h3>
                            <p className="ow-features__text">{text[lang]["h2_prod_6_description"]}</p>
                            <img className="ow-features__img" src={u04Img} alt={text[lang]["h2_prod_6_alt"]}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="ow-features__title">{text[lang]["h2_prod_7_title"]}</h3>
                            <p className="ow-features__text">{text[lang]["h2_prod_7_description"]}</p>
                            <img className="ow-features__img" src={u01Img} alt={text[lang]["h2_prod_7_alt"]}/>
                        </div>
                        <div className="col-md-6">
                            <h3 className="ow-features__title">{text[lang]["h2_prod_8_title"]}</h3>
                            <p className="ow-features__text">{text[lang]["h2_prod_8_description"]}</p>
                            <img className="ow-features__img" src={u02Img} alt={text[lang]["h2_prod_8_alt"]}/>
                        </div>  
                    </div>
                
                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="ow-features__title">{text[lang]["h2_prod_9_title"]}</h3>
                            <p className="ow-features__text">{text[lang]["h2_prod_9_description"]}</p>
                            <img className="ow-features__img" src={u05Img} alt={text[lang]["h2_prod_9_alt"]}/>
                        </div>
                        <div className="col-md-6">
                            <h3 className="ow-features__title">{text[lang]["h2_prod_10_title"]}</h3>
                            <p className="ow-features__text">{text[lang]["h2_prod_10_description"]}</p>
                            <img className="ow-features__img" src={u06Img} alt={text[lang]["h2_prod_10_alt"]}/>
                        </div>  
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <h3 className="ow-features__title">{text[lang]["h2_prod_11_title"]}</h3>
                            <p className="ow-features__text">{text[lang]["h2_prod_11_description"]}</p>
                            <img className="ow-features__img" src={u01Img} alt={text[lang]["h2_prod_11_alt"]}/>
                        </div>
                    </div>

                    <div className="features__cta mt-5 mb-5">
                            <OptBtn clickCb={() => setModalIsOpen(true)} rounded small>{text[lang]["register"]}</OptBtn>
                    </div>
                </div>   
            </section>

            {
                /*
                ************
                * CUSTOMERS *
                ************
                */
            }
            <section className="clients-shapes">
                <div className="client_top_shape_wrapper">
                    <svg className="svg_top_shape" id="client_top_shape" data-name="client_top_shape" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice"  viewBox="0 0 1920 595"><path className="clients_top_shape" d="M1920,595V0c-89.19,9.65-163.93,79.77-218.34,153.38-59,79.8-107.78,170.9-189.35,227.3-42.59,29.6-92,47.9-142.07,60.9-54.28,14.1-110.67,22.1-166.55,17.3-88.88-7.6-172.16-47.2-260.44-59.5a433,433,0,0,0-255.13,43c-37.89,19.4-72.78,44.3-110.57,63.9C472.28,561,345,571.38,232.24,534.68,151.25,508.33,76.61,458.85,0,420.92V595Z"/></svg>
                </div>
                <div className="container-fluid ow-section ow-section--success-stories" id="success-stories">
                    <div className="container success-stories">
                        <div className="row justify-content-center mb-5">
                            <div className="col-md-10">
                                <h2 className="ow-section__title"><span className="bold">{text[lang]["h2_cs"]}</span></h2>
                            </div>
                            <div className="row">
                                <div className="col-md-10">
                                </div>
                            </div>
                        </div>
                        <Stories stories={clientStories} />
                    </div>
                </div>
                <svg id="client_bottom_shape" data-name="client_bottom_shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 170"><path className="client_bottom_shape" d="M0,0V126.36c143.66,40.06,284.7,51.63,445.48,4.58,236.34-69.2,456.88-50.6,696.62-15.6,149.46,21.9,301.72,27,451.88,8.7,111.21-13.59,217-45.86,326-64.76V0Z"/></svg>
            </section>

            {
                /*
                ************
                * CHANNELS *
                ************
                */
            }
            <section className="container-fluid ow-section ow-section--channels" id="channels">
                <div className="container">
                    <div className="row d-flex align-items">
                        <div className="col-md-6 d-flex align-items-center order-1 order-ms-2">
                            <div className="ow-section__content">
                                <h2 className="ow-section__title"><span className="bold">{text[lang]["h2_canales"]}</span></h2>
                                <h3 className="ow-section__subtitle">{text[lang]["h2_canales_h3"]}</h3>
                                <p className="ow-section__text">{text[lang]["h2_canales_description"]}</p>
                                <div className="row ow-section--channels__btn">
                                    <OptBtn clickCb={() => setModalIsOpen(true)} rounded small>{text[lang]["test"]}</OptBtn>
                                </div>
                                <div className="row ow-section--channels__btn">
                                    <OptBtn isLink href="https://business.whatsapp.com/products/business-platform" rounded small className="ws-button">{text[lang]["h2_canales_whatsapp_platform"]}</OptBtn>
                                </div>
                                <div className="row ow-section--channels__btn">
                                    <OptBtn isLink href="https://developers.facebook.com/docs/whatsapp/" rounded small className="ws-button">{text[lang]["h2_canales_whatsapp_business_api"]}</OptBtn>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 order-2 order-md-1">
                            <img src={mobile} alt="Optiwe" className="img-responsive"/>
                        </div>
                    </div>
                </div>
            </section>

            {
                /*
                ****************
                * INTEGRATIONS *
                ****************
                */
            }
            <section className="integrations-shapes">
                <svg id="integrations_top_shape" data-name="integrations_top_shape 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 88"><path className="integrations_top_shape" d="M1920.5,88V43.27C1753,54.14,1581.09,22.09,1411.3,8.49a2363.54,2363.54,0,0,0-388.3,1c-107.6,9.2-212.8,28.4-321.2,34.8-102.5,6.1-195.5,5.9-297.8-3.9C276.5,28.13,126.69,6.36.5,30.37V88Z"/></svg>
                <div className="container-fluid ow-section ow-section--clients" id="integrations">
                    <div className="container">
                        <h2 className="ow-section--clients__title"><span className="bold">{text[lang]["h2_integrations"]}</span></h2>
                        <Integrations />
                    </div>
                </div>
                <svg id="integrations_bottom_shape" data-name="integrations_bottom_shape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 82.17"><path className="integrations_bottom_shape" d="M0,0V25.07C100.42,73.33,222.92,85.71,339.3,81.39c156.8-5.8,310.1-35.9,467.5-35.3,150.2.5,300.5,20.5,450.6,13.9,134.5-5.9,267.1-42.9,402.7-27.6,84,9.52,172.75,28.69,259.9,33.2V0Z"/></svg>
            </section>

            {
                /*
                ********
                * FORM *
                ********
                */
            }
            <section id="form" className="container-fluid ow-section ow-section--form">
                <div className="container ow-contact-form">
                    <svg className="form-shape-left" width="433" height="506" viewBox="0 0 433 506" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M173.471 25.2801C151.952 37.2566 133.247 53.4108 122.712 75.47C114.447 93.0812 111.811 112.874 105.997 131.485C95.3231 165.665 66.814 177.82 42.7513 201.508C13.3311 231.187 -2.24674 274.173 1.42225 315.804C4.6837 352.124 24.559 388.36 62.0974 396.41C79.8453 400.276 98.2295 400.056 115.614 405.375C173.26 423.285 237.059 546.157 307.655 491.481C341.841 465.118 346.528 403.969 375.314 369.115C388.254 353.639 402.737 339.708 414.542 323.369C433.93 296.002 441.017 263.046 417.009 236.387C391.459 208.185 368.723 176.443 355.066 140.8C339.368 100.208 341.83 41.0097 302.888 13.1197C276.655 -5.41316 240.703 -0.524527 210.378 9.63251C197.758 13.8041 185.32 18.7929 173.471 25.2801Z" fill="#CFEEFF"/>
                    </svg>
                    <svg width="167" height="97" viewBox="0 0 167 97" fill="none" xmlns="http://www.w3.org/2000/svg" className="form-shape-right">
                    <path d="M65.0883 57.0113C78.4812 51.2343 95.3737 59.6005 107.556 51.9131C116.641 46.2259 118.907 32.4992 113.943 22.9897C108.72 13.5344 98.0807 7.91682 87.0678 6.97531C65.0963 5.35141 -21.49 39.9646 17.0599 73.0097C34.0125 88.1249 51.1774 62.8968 65.0883 57.0113Z" fill="#CFEEFF"/>
                    <path d="M158.323 96.3491C159.117 96.0844 160.176 95.8197 160.97 95.2902C164.411 93.4371 166.263 89.4662 166.793 85.7599C167.322 82.0537 165.999 78.0827 164.675 74.3765C163.352 70.935 161.235 66.9641 157.794 65.9052C153.295 64.3168 144.825 66.6993 143.237 71.7292C142.443 74.3765 143.767 77.2885 145.355 79.9359C146.413 81.789 148.001 83.6421 148.531 85.7599C149.06 88.1425 148.266 90.2603 148.531 92.3782C148.795 94.496 150.913 95.555 152.765 96.0844C154.353 96.6139 156.471 96.6139 158.323 96.3491Z" fill="#CFEEFF"/>
                    </svg>
                    <h2 className="ow-section__title">{text[lang]["talk"]}</h2>
                    <div className="ow-card ow-card--shadow ow-card--form-footer">
                        <ContactForm id="footer" lang={lang}/>
                    </div>
                    <svg className="form-shape-bottom" width="439" height="385" viewBox="0 0 439 385" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M41.4606 347.388C20.8166 328.063 5.99523 301.325 1.23122 267.175C-10.9435 181.137 70.0447 179.02 117.42 136.398C144.416 112.043 156.062 75.5098 181.47 49.5662C208.201 22.0343 244.99 4.03261 283.366 0.855847C305.069 -0.997267 328.889 2.70896 344.505 18.0633C360.12 33.6824 363.561 56.9787 369.648 78.1572C383.146 124.75 426.022 155.723 436.873 201.257C442.696 225.347 435.55 251.026 423.905 272.999C413.318 292.854 398.761 310.855 379.705 322.503C348.475 341.564 319.096 328.063 286.013 335.475C255.841 342.358 232.286 366.449 202.643 375.714C143.622 394.245 81.1607 384.45 41.4606 347.388Z" fill="#CFEEFF"/>
                    </svg>
                </div>
            </section> 

            <section className="benefits-shapes">
                    <svg id="benefits_top_shape" data-name="benefits_top_shape" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" viewBox="0 0 1920 143.11"><path className="benefits_top_shape" d="M1920,143.11V38.75C1692.68,104.88,1412.86,127.9,1184.41,89,1083.28,71.8,983,44.3,880.43,45.1,705.88,46.4,536.94,129.6,363.09,114c-77.32-7-151.94-33.2-229.26-39.5C89,70.85,41.09,75.79,0,92.94l.22,50.16Z"/><path className="benefits_top_shape" d="M384,72.49c5.25-.88,10.65-1.89,15.23-4.62s8.23-7.75,7.92-13-4.74-9.86-9.87-11.6-10.8-1-15.93.58c-4,1.19-8.2,3-12.06,1.58-4.14-1.57-6.17-6-8.85-9.53C353,25.85,337.1,22.4,327.17,30c-6,4.54-9.81,15.35-7.06,22.55a25.66,25.66,0,0,0,5.42,8c6.64,6.93,17.09,14.23,27,15.22S374,74.25,384,72.49Z"/><path className="benefits_top_shape" d="M443.55,31.61c3.18-3.33,5.56-8.65,2.74-12.32-2.34-3-7.15-3-10.31-.93-1.72,1.09-4.24,3.37-4.92,5.41-1.2,3.23-1.83,9.65,1.81,11.43C436.71,37.19,440.95,34.38,443.55,31.61Z"/></svg>
                    <div className="container-fluid ow-section ow-section--faq">
                        <div className="container">
                            <div className="table-pricing-title">
                                <h2 className="ow-section__title"><span className="bold">{text[lang]["h2_faq"]}</span></h2>
                            </div>
                            <FaqCard _isOpen={true} title={text[lang]["faq_1"]} text={text[lang]["faq_1_description"]} />
                            <FaqCard _isOpen={true} title={text[lang]["faq_2"]} text={text[lang]["faq_2_description"]} />
                            <FaqCard _isOpen={true} title={text[lang]["faq_3"]} text={text[lang]["faq_3_description"]} />
                            <FaqCard _isOpen={true} title={text[lang]["faq_4"]} text={text[lang]["faq_4_description"]} />
                            <FaqCard _isOpen={true} title={text[lang]["faq_4"]} text={text[lang]["faq_5_description"]} />
                        </div>
                    </div>
                </section>
        </Layout>
    )
}
export default Sales;
